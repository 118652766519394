import { notificationTypes } from '@/helpers/enums';
import store from '@/store';

export const notificationOptions = {
	icon: '/logo.png',
	dir: 'rtl',
	lang: 'ar-SA',
};

export const notificationPageRedirect = [
	{ type: notificationTypes.message, redirect: '/chat', routeName: 'chat' },
	{ type: notificationTypes.attendance, redirect: '/attendance', routeName: 'attendance' },
	{ type: notificationTypes.newMark, redirect: '/grades', routeName: 'grades' },
	{ type: notificationTypes.newMarks, redirect: '/grades', routeName: 'grades' },
	{ type: notificationTypes.schedule, redirect: '/schedule', routeName: 'schedule' },
	{ type: notificationTypes.newTest, redirect: '/exams', routeName: 'exams' },
	{ type: notificationTypes.testsSchedule, redirect: '/exams', routeName: 'exams' },
	{ type: notificationTypes.transport, redirect: '/', routeName: 'home' },
	{ type: notificationTypes.notes, redirect: '/behavior', routeName: 'behavior' },
];

export const firebase = {
	configs: {
		apiKey: 'AIzaSyDBqDcPhh9UE1FmCKjtp2W_m6I_m0vGyzY',
		authDomain: 'dar-alfkr-app.firebaseapp.com',
		projectId: 'dar-alfkr-app',
		storageBucket: 'dar-alfkr-app.appspot.com',
		messagingSenderId: '52248080753',
		appId: '1:52248080753:web:cbdaed83b42561946a06d0'
	},
	publicKey: 'BPylZ0kc8pyJ4QpP9MfgqSVxB9edT28vD-xyRdsSz6Z9saH0UDHh1Rq1ZQ7W_ZiPER5O_h72GYKZecSqulUFwp0',
};

export const onGetNotification = () => {
	store.dispatch('notification/fetchAll');
};
