module.exports = {
	colors: {
		main: '#e63e44',
		sec: '#3C3C3C',
		alt: '#e63e44',
	},

	showFunds: true,

	showBuses: false,
		
	showHomeworks: true,

	about: {
		mobiles: ['+963988222776'],
		phones: ['0212642707', '0212673640'],
		address: 'حي السبيل _ شارع الماركات _ مفرق دوار مشفى الكلمة',
		data: [0, 0],
		email: null,
		facebook: 'https://www.facebook.com/daralfikr.org',
		privacy: null,
		details: [
			{
				title: 'معلومات عن المعهد',
				content: [
					'مؤسسة تربويّة تعليميّة أكاديميّة تهدف إلى إنشاء جيل متعلّم وناجح.',
					'بدأنا من ربع قرن باستقطاب جميع الفئات العمريّة من الطفولة حتى الشباب.',
					'كنا من أوائل المعاهد التي اختصّت بتعليم اللغات الأجنبيّة للمراحل كافّة ولجميع المستويات.',
					'يتابع المعهد اليوم مسيرته بافتتاح الدورات التعليميّة لطلاب الشهادتين الإعداديّة والثانويّة بفرعيها.',
					'اعتمدنا في تشكيل الهيئة التدريسيّة على نخبة من المدرسين ذوي الخبرة الطويلة والكفاءة العالية.',
					'نعتمد على البناء الأكاديمي للطالب فيمرّ بمرحلة التأسيس ثم التعلّم ثم المهارات ثم الاختبار والقياس.',
				],
			},
		],
	},

	pwa: {
		name: 'دار الفكر',
		shortName: 'دار الفكر',
		description: 'تطبيق دار الفكر - نسخة محمولة من تطبيق الموبايل.',
	},
};
